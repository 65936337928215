import { useState, useEffect } from 'react';

const DEFAULT_THRESHOLD = 1.75;

const useZoomLevel = (zoomThreshold = DEFAULT_THRESHOLD) => {
  const [isZoomed, setIsZoomed] = useState(false);

  const checkZoomLevel = () => {
    setIsZoomed(window.devicePixelRatio > zoomThreshold);
  };

  useEffect(() => {
    checkZoomLevel();
    window.addEventListener('resize', checkZoomLevel);
    return () => {
      window.removeEventListener('resize', checkZoomLevel);
    };
  }, [zoomThreshold]);
  return isZoomed;
};

export default useZoomLevel;
