import useZoomLevel from '../hooks/useZoomLevel';
import { useEffect } from 'react';

const ZoomHandler = ({ onZoomChange }) => {
  const isZoomed = useZoomLevel(2.0);

  useEffect(() => {
    onZoomChange(isZoomed);
  }, [isZoomed, onZoomChange]);

  return null;
};

export default ZoomHandler;
